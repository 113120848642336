import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer } from 'components';
import {
  usePartnerPageData,
  Header,
  PartnerCategory
} from 'sections/partnerPage';

// API Data
import { usePartnersGroupedByCategory } from 'boxenStopHooks';

// External Data
import { useKisPartnerPageData } from 'boxenStopHooks';

const Partner = () => {
  const { seo, header, categoriesGroup, partnerCard } = usePartnerPageData();
  const data = usePartnersGroupedByCategory(categoriesGroup);

  const { header: cmsHeader, partner_card: cmsPartnerCard } =
    useKisPartnerPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={cmsHeader} staticData={header} />
      <Spacer
        height={[
          '17.2rem',
          '20rem',
          '21.6rem',
          '15.4rem',
          '22.8rem',
          '22.3rem'
        ]}
      />
      {data.map((categoryWithPartner) => {
        return (
          <React.Fragment key={categoryWithPartner.category}>
            <PartnerCategory
              data={categoryWithPartner}
              partnerCard={cmsPartnerCard}
            />
            {/* Add the spacer below the card */}
            <Spacer
              height={['16rem', '18rem', '18rem', '12rem', '16rem', '20rem']}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default injectIntl(Partner);
