import React from 'react';

// External Components
import { Box, Section, Heading, Link } from '@thepuzzlers/pieces';

// Local Components
import { CardButton, HorizontalLine, PointerCircle } from 'components';

// Utils
import { convertStringToSlug } from 'helper';

export const PartnerCategory = ({
  data: { category, partners },
  partnerCard: { weblinklabel }
}) => {
  const getSectionId = (category) => {
    const cat = convertStringToSlug(category);

    return `partner-category__${cat}`;
  };

  return (
    // Markup
    <Section id={getSectionId(category)}>
      <CategoryName data={category} />
      <Line />
      <PartnersList partners={partners} webLinkText={weblinklabel} />
    </Section>
  );
};

// Elements

const CategoryName = ({ data }) => (
  <Heading
    as="h2"
    sx={{
      fontFamily: 'primary.bold',
      fontSize: ['2.8rem', '3.4rem', '4rem', '2.8rem', '3.6rem', '4rem'],
      gridColumn: [
        '1/13',
        '2/span 10',
        '5/span 16',
        '1/25',
        '2/span 22',
        '3/span 20'
      ],
      lineHeight: 1.25
    }}>
    {data}
  </Heading>
);

const PartnersList = ({ partners, webLinkText }) =>
  partners?.map((partner, index) => {
    return (
      <React.Fragment key={index}>
        {index !== 0 && (
          <Line
            sx={{
              display: ['none', 'none', 'none', 'block', 'block', 'block']
            }}
          />
        )}
        <PartnerCard partner={partner} webLinkText={webLinkText} />
      </React.Fragment>
    );
  });

const PartnerCard = ({
  partner: { name, description, websiteUrl },
  webLinkText
}) => {
  return (
    <CardWrapper webLinkText={webLinkText} websiteUrl={websiteUrl}>
      <CardTitle data={name} />
      <CardDescription data={description.html} />
      {websiteUrl && (
        <CardButton
          // interactive element cannot be inside <a> tag since the the card wrapper is a tag
          // https://stackoverflow.com/questions/6393827/can-i-nest-a-button-element-inside-an-a-using-html5#:~:text=The%20a%20element%20may%20be,the%20controls%20attribute%20is%20present)
          as="div"
          text={webLinkText}
          sx={{
            display: ['flex', 'flex', 'flex', 'flex', 'flex', 'block'],
            fontSize: [
              '1.3rem',
              '1.5rem',
              '1.5rem',
              '1.2rem',
              '1.4rem',
              '1.4rem'
            ],
            gridColumn: [1, 1, 1, 5, 5, 5],
            mt: ['3.2rem', '3.2rem', '3.2rem', 0, 0, 0],
            width: '100%',
            // styles for the text
            '& > span:first-of-type': {
              display: [null, null, null, null, null, 'none']
            }
          }}
          initial={undefined}
          whileHover={undefined}
        />
      )}
    </CardWrapper>
  );
};

const CardWrapper = ({ webLinkText, websiteUrl, children, ...props }) => (
  <Link
    href={`https://${websiteUrl}`}
    target="__blank"
    variant="noStyle"
    initial="initial"
    whileHover="animate"
    className="partner-category__partner-card"
    sx={{
      alignItems: 'baseline',
      display: 'grid',
      gridColumn: [
        '1/13',
        '2/span 10',
        '5/span 16',
        '1/25',
        '2/span 22',
        '3/span 20'
      ],
      gridTemplateColumns: [
        '1fr',
        '1fr',
        '1fr',
        '16.6rem 3.7rem 31.1rem 6.6rem 1fr',
        '24.4rem 5rem 37rem 9.2rem 1fr',
        '30.8rem 12.4rem 36.2rem 12.4rem 1fr'
      ],
      ':not(:first-of-type)': {
        mt: ['5.2rem', '6.4rem', '6.4rem', 0, 0, 0]
      },
      cursor: ['pointer', 'pointer', 'pointer', 'pointer', 'pointer', 'none']
    }}>
    {children}
    <PointerCircle text={webLinkText} />
  </Link>
);

const CardTitle = ({ data }) => (
  <Heading
    as="h3"
    sx={{
      fontFamily: 'primary.bold',
      gridColumn: 1,
      lineHeight: 1.25,
      fontSize: ['2.2rem', '2.6rem', '2.6rem', '1.8rem', '2rem', '2.4rem']
    }}>
    {data}
  </Heading>
);

const CardDescription = ({ data }) =>
  /* Box instead of Paragraph because description already comes from the api wrapper in <p></p> tags */
  data.map((description) => (
    <Box
      key={description}
      dangerouslySetInnerHTML={{
        __html: description
      }}
      sx={{
        gridColumn: [1, 1, 1, 3, 3, 3],
        fontSize: ['1.5rem', '1.7rem', '1.7rem', '1.5rem', '1.6rem', '1.6rem'],
        lineHeight: 1.5,
        mt: ['0.8rem', '1.2rem', '1.2rem', 0, 0, 0],
        fontFamily: 'body.normal'
      }}
    />
  ));

const Line = ({ sx }) => (
  <HorizontalLine
    isBold
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '5/span 16',
        '1/25',
        '1/25',
        '2/span 22'
      ],
      my: ['3.2rem', '4.8rem', '4.8rem', '3.2rem', '4.4rem', '8.4rem'],
      ...sx
    }}
  />
);
