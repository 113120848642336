import React from 'react';

// External Components
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

export const Header = ({
  data: { headline, description },
  staticData: { image }
}) => {
  return (
    // Markup
    <Section id="partner__header">
      <DartImage image={image} />
      <Headline headline={convertApiHtmlText(headline.html)} />
      <Description description={convertApiHtmlText(description.html)} />
    </Section>
  );
};

// Elements

const DartImage = ({ image }) => {
  return (
    <ImageContainer
      src={image}
      alt=""
      sx={{
        gridColumn: [
          '1 / span 10',
          '1 / span 10',
          '1 / span 16',
          '1 / span 9',
          '1 / span 10',
          '1 / span 9'
        ],
        gridRow: [null, null, null, '1 / span 2', '1 / span 2', '1 / span 3'],
        justifySelf: 'end',
        width: [
          '30.5rem',
          '48.6rem',
          '53.9rem',
          '35.2rem',
          '53.4rem',
          '65.7rem'
        ],
        height: [
          '30.5rem',
          '48.6rem',
          '53.9rem',
          '35.2rem',
          '53.4rem',
          '65.7rem'
        ],
        position: 'relative',
        mt: ['1rem', '1rem', '1rem', '-1rem', '2rem', 0],
        zIndex: 3
      }}
    />
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h1"
      dangerouslySetInnerHTML={{ __html: headline }}
      sx={{
        fontFamily: 'primary.bold',
        lineHeight: 1.1,
        fontSize: ['4.8rem', '6.4rem', '8rem', '4.8rem', '7.2rem', '8.8rem'],
        gridColumn: [
          '1/13',
          '2 / span 9',
          '4 / span 13',
          '11 / span 8',
          '12 / span 8',
          '12 / span 8'
        ],
        mt: ['6rem', '3.8rem', '7.2rem', '6rem', '11.2rem', '9.7rem'],
        '& > span': {
          fontFamily: 'primary.italic'
        }
      }}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      sx={{
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem'],
        gridColumn: [
          '1/13',
          '4/13',
          '7/span 16',
          '13/25',
          '14/span 10',
          '14/span 9'
        ],
        lineHeight: 1.5,
        mt: ['2.1rem', '3.2rem', '4rem', '4.4rem', '4rem', '4.2rem']
      }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};
